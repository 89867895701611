import { createRouter, createWebHistory } from "vue-router";

import CVCompanyComponent from "./components/cv.component.vue";
import LoginComponent from "./components/login.component.vue";
import NotFoundComponent from "./components/not-found.component.vue";

import * as middlewares from "./middlewares";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/cv",
      name: "cv",
      component: CVCompanyComponent,
      beforeEnter: middlewares.isAuth,
    },
    {
      path: "/login",
      name: "login",
      component: LoginComponent,
      beforeEnter: middlewares.isGuest,
    },
    {
      path: "/logout",
      name: "logout",
      component: { beforeRouteEnter: middlewares.logout },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFoundComponent,
      props: { code: 404 },
    },
  ],
});
