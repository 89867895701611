<template>
    <Menubar :model="items">
      <template #start>
        <div class="mr-2">
          <i class="fa-solid fa-user-tie fa-2xl"></i>
          CV
        </div>
      </template>
      <template #item="{ item, props }">
        <router-link v-slot="{ href, navigate, isActive }" :to="item.route" custom>
          <a :href="href" :class="{ 'router-link-active': isActive }" v-bind="props.action" @click="navigate">
            {{ item.label }}
          </a>
        </router-link>
      </template>
      <template #end>
        <div class="flex gap-3">
          <template v-if="!authStore.isAuth">
            <router-link to="/login" active-class="none">
              <Button label="Login" link />
            </router-link>
          </template>
          <template v-else>
            <router-link to="/settings" active-class="none">
              <Button icon="pi pi-cog" link />
            </router-link>
            <router-link class="p-button no-underline" to="/logout">
              Logout
            </router-link>
          </template>
        </div>
      </template>
    </Menubar>
</template>
<script lang="ts" setup>
// Libs
import { computed } from 'vue';

// PrimeVue
import Menubar from 'primevue/menubar';
import { MenuItem } from 'primevue/menuitem';
import Button from "primevue/button";

// Stores
import { useAuthStore } from "../stores/auth.store";

const authStore = useAuthStore();

const items = computed<MenuItem[]>(() => authStore.isAuth ? [
  {
    label: "CV",
    route: "/cv"
  }
]: []);

</script>
<style scoped>
  .router-link-active {
    background-color: #e5e7eb;
    border-radius: 6px;
  }
</style>