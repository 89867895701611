// Libs
import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n"
import PrimeVue from "primevue/config";
import DialogService from "primevue/dialogservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";

// Locale
import en from "../resources/locales/en.json";
import sv from "../resources/locales/sv.json";

// Components
import AppComponent from "./components/app.component.vue";

// Stores
import { useSurrealdbConnectionStore } from "./stores/surrealdb-connection.store";
import { useTokenStore } from "./stores/token.store";

// Router
import { router } from "./router";

const pinia = createPinia();
const i18n = createI18n({
  legacy: false,
  locale: "sv",
  fallbackLocale: "en",
  messages: {
    en,
    sv
  }
});

const app = createApp(AppComponent);

app.use(pinia);
app.use(i18n)
app.use(router);
app.use(PrimeVue);
app.use(DialogService);
app.use(ConfirmationService);

app.directive("tooltip", Tooltip);

const surrealdbConnectionStore = useSurrealdbConnectionStore();
const tokenStore = useTokenStore();

(async (): Promise<void> => {
  tokenStore.$onAction(({ name }) => {
    if (name === "clearToken") {
      router.push({ name: "login" });
    }
  });

  await surrealdbConnectionStore.connect();

  app.mount("#app");
})();
