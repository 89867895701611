{
  "name": "CV",
  "language": {
    "en": "Engelska",
    "sv": "Svenska"
  },
  "language_label": "Språk",
  "menu": {
    "start": "Start",
    "cv": "CV"
  },
  "cv": {
    "industry_competencies": "Yrke, specialisering",
    "years_of_experience": "År av erfarenhet",
    "key_words": "Nyckelkvalifikationer",
    "field_of_expertise": "Expertområden",
    "person": {
      "mobile": "Mobil",
      "email": "E-post",
      "birth_year": "Födelseår"
    },
    "assignments": "Urval av uppdrag och projekt",
    "educations": "Utbildning",
    "education_format": "{degree} vid {school} {years}",
    "courses": "Kurser",
    "employments": "Anställningar",
    "language_skills": "Språk",
    "language_skill_level": {
      "basic": "grundläggande",
      "good": "god",
      "fluent": "flytande",
      "native": "modersmål"
    }
  },
  "month": {
    "jan": {
      "short": "jan",
      "long": "januari"
    },
    "feb": {
      "short": "feb",
      "long": "februari"
    },
    "mar": {
      "short": "mar",
      "long": "mars"
    },
    "apr": {
      "short": "apr",
      "long": "april"
    },
    "may": {
      "short": "maj",
      "long": "maj"
    },
    "jun": {
      "short": "jun",
      "long": "juni"
    },
    "jul": {
      "short": "jul",
      "long": "juli"
    },
    "aug": {
      "short": "aug",
      "long": "augusti"
    },
    "sep": {
      "short": "sep",
      "long": "september"
    },
    "oct": {
      "short": "okt",
      "long": "oktober"
    },
    "nov": {
      "short": "nov",
      "long": "november"
    },
    "dec": {
      "short": "dec",
      "long": "december"
    }
  }
}