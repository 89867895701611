import { RouteLocationNormalizedLoaded } from "vue-router";

export function dateFormat(date: Date | string, format: string): string {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return format
    .replace("YYYY", date.getFullYear().toString())
    .replace("MM", padLeft(date.getMonth() + 1, 2, "0"))
    .replace("DD", padLeft(date.getDate(), 2, "0"))
    .replace("mm", `${date.getMonth() + 1}`)
    .replace("dd", `${date.getDate()}`)
    .replace("HH", padLeft(date.getHours(), 2, "0"))
    .replace("hh", `${date.getHours()}`)
    .replace("II", padLeft(date.getMinutes(), 2, "0"))
    .replace("ii", `${date.getMinutes()}`);
}

export function swedishKronerFormat(value: number): string {
  const integer = Math.floor(value);
  const decimal = Math.round((value - integer) * 100);
  return (
    thousandSeparator(integer.toString(), " ", 3) +
    "," +
    padLeft(decimal, 2, "0") +
    " kr"
  );
}

function thousandSeparator(
  value: string,
  separator: string,
  groupSize: number
): string {
  const parts: string[] = [];
  for (let i = value.length; i > 0; i -= groupSize) {
    parts.push(value.substring(Math.max(0, i - groupSize), i));
  }
  return parts.reverse().join(separator);
}

export function padLeft(
  value: number,
  length: number,
  padChar: string
): string {
  return value.toString().padStart(length, padChar);
}

export function queryStringFromRoute(
  route: RouteLocationNormalizedLoaded,
  param: string
): string | undefined {
  const value = route.query[param];
  if (typeof value === "string") {
    return value;
  } else if (
    value instanceof Array &&
    value.length > 0 &&
    typeof value[0] === "string"
  ) {
    return value[0];
  }
}

export function paramFromRoute(
  route: RouteLocationNormalizedLoaded,
  param: string
): string | undefined {
  const value = route.params[param];
  if (typeof value === "string") {
    return value;
  } else if (value instanceof Array && value.length > 0) {
    return value[0];
  }
}

export function getWeekNumberFromDate(date: Date): number {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
}

export function isToday(date: Date, today: Date): boolean {
  return dateFormat(date, "YYYY-MM-DD") === dateFormat(today, "YYYY-MM-DD");
}

export function startOfDay(date: Date): Date {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
}

export function endOfDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}
