// Libs
import { defineStore } from "pinia";

// Stores
import { useSurrealdbConnectionStore } from "./surrealdb-connection.store";
import { CV } from "../dto/cv";

export const useCvStore = defineStore("cv", () => {
  const surrealdbStore = useSurrealdbConnectionStore();
  
  async function list(languageCode: string): Promise<{ id: string, title: string }[]> {
    return (await surrealdbStore.db.query<{ id: string, title: string }[][]>(`
      SELECT
        id,
        (SELECT * FROM $parent.title WHERE language.code = $languageCode)[0].content as title
      FROM cv
    `, {
      languageCode
    }))[0];
  }

  async function get(id: string, languageCode: string): Promise<CV> {
    return (await surrealdbStore.db.query<CV[][]>(`
      SELECT
        *,
        (
          SELECT
            *,
            (SELECT * FROM $parent.name WHERE language.code = $languageCode)[0].content as name
          FROM $parent.organization
        )[0] AS organization,
        associate.*,
        (SELECT * FROM $parent.text WHERE language.code = $languageCode)[0].content as text,
        (SELECT * FROM $parent.title WHERE language.code = $languageCode)[0].content as title,
        (
          SELECT
            *,
            (SELECT * FROM $parent.name WHERE language.code = $languageCode)[0].content as name
          FROM $parent.skills
        ) AS skills,
        (
          SELECT
            *,
            (SELECT * FROM $parent.name WHERE language.code = $languageCode)[0].content as name
          FROM $parent.fieldsOfExpertise
        ) AS fieldsOfExpertise,
        (
          SELECT
            *,
            (SELECT * FROM $parent.company WHERE language.code = $languageCode)[0].content as company,
            (SELECT * FROM $parent.location WHERE language.code = $languageCode)[0].content as location,
            (SELECT * FROM $parent.role WHERE language.code = $languageCode)[0].content as role,
            (SELECT * FROM $parent.text WHERE language.code = $languageCode)[0].content as text,
            (
              SELECT
                *,
                (SELECT * FROM $parent.name WHERE language.code = $languageCode)[0].content as name
              FROM $parent.skills
            ) AS skills
          FROM $parent.assignments
        ) AS assignments,
        (
          SELECT
            *,
            (SELECT * FROM $parent.company WHERE language.code = $languageCode)[0].content as company
          FROM $parent.employments
        ) AS employments,
        (
          SELECT
            *,
            (SELECT * FROM $parent.degree WHERE language.code = $languageCode)[0].content as degree,
            (SELECT * FROM $parent.school WHERE language.code = $languageCode)[0].content as school
          FROM $parent.educations
        ) AS educations,
        (
          SELECT
            *,
            (SELECT * FROM $parent.language.name WHERE language.code = $languageCode)[0].content as language
          FROM $parent.languageSkills
        ) AS languageSkills,
        (
          SELECT
            *,
            (SELECT * FROM $parent.name WHERE language.code = $languageCode)[0].content as name
          FROM $parent.courses
        ) AS courses
      FROM $id
    `, {
      id,
      languageCode
    }))[0][0];
  }

  return {
    list,
    get
  };
});
