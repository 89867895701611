{
  "name": "CV",
  "language": {
    "en": "English",
    "sv": "Swedish"
  },
  "language_label": "Language",
  "menu": {
    "start": "Start",
    "cv": "CV"
  },
  "cv": {
    "industry_competencies": "Industry specific competence",
    "years_of_experience": "Year of experience",
    "key_words": "Key qualifications",
    "field_of_expertise": "Field of expertise",
    "person": {
      "mobile": "Mobile",
      "email": "Email",
      "birth_year": "Year of Birth"
    },
    "assignments": "Sample of assignments and projects",
    "educations": "Education",
    "education_format": "{degree} at {school} {years}",
    "courses": "Courses",
    "employments": "Employments",
    "language_skills": "Languages",
    "language_skill_level": {
      "basic": "Basic",
      "fluent": "Fluent",
      "good": "Good",
      "native": "Native"
    }
  },
  "month": {
    "jan": {
      "short": "Jan",
      "long": "January"
    },
    "feb": {
      "short": "Feb",
      "long": "February"
    },
    "mar": {
      "short": "Mar",
      "long": "March"
    },
    "apr": {
      "short": "Apr",
      "long": "April"
    },
    "may": {
      "short": "May",
      "long": "May"
    },
    "jun": {
      "short": "Jun",
      "long": "June"
    },
    "jul": {
      "short": "Jul",
      "long": "July"
    },
    "aug": {
      "short": "Aug",
      "long": "August"
    },
    "sep": {
      "short": "Sep",
      "long": "September"
    },
    "oct": {
      "short": "Oct",
      "long": "October"
    },
    "nov": {
      "short": "Nov",
      "long": "November"
    },
    "dec": {
      "short": "Dec",
      "long": "December"
    }
  }
}