<template>
  <layout-component>
    <div class="grid">
      <div class="col-12 flex gap-3 align-items-center">
        <label for="locale">{{ $t("language_label") }}:</label>
        <Dropdown input-id="locale" :options="[{ label: 'EN', value: 'en' }, { label: 'SV', value: 'sv' }]" option-value="value" option-label="label" v-model="locale" />
        <label for="cv">CV:</label>
        <Dropdown input-id="cv" :options="cvs" option-value="id" option-label="title" v-model="selectedCv" />
      </div>
      <div class="col-12">
        <iframe class="w-full h-screen" ref="iframe" />
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from 'vue-i18n'
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// PrimeVue
import Dropdown from "primevue/dropdown";

// Resources
import treeImage from "../../resources/img/tree.png";

// Components
import LayoutComponent from './layout.component.vue';

// Stores
import { useCvStore } from "../stores/cv.store";

const { t: $t, locale } = useI18n();

const cvStore = useCvStore();

const paralucent = new URL(
  "../../resources/fonts/paralucent.woff2",
  import.meta.url
);

const latoThin = new URL(
  "../../resources/fonts/lato-thin.ttf",
  import.meta.url
);

const latoLight = new URL(
  "../../resources/fonts/lato-light.ttf",
  import.meta.url
);

const currentLanguage = computed(() => locale.value);

const iframe = ref<HTMLIFrameElement | null>(null);

const cvs = ref<{ id: string, title: string }[]>([]);
const selectedCv = ref<string | null>(null);

onMounted(async () => {
  await loadCVS();
  selectedCv.value = cvs.value[0] ? cvs.value[0].id : null;
  generatePDF();
});

watch([currentLanguage, selectedCv], () => {
  loadCVS();
  generatePDF();
});

async function loadCVS() {
  cvs.value = await cvStore.list(currentLanguage.value);
}

async function generatePDF(): Promise<void> {
  if(selectedCv.value === null) {
    return;
  }
  const cv = await cvStore.get(selectedCv.value, currentLanguage.value);
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = {
    paralucent: {
      normal: `${paralucent}`,
    },
    lato: {
      normal: `${latoThin}`,
      bold: `${latoLight}`,
    },
  };

  function hr() {
    return {
      stack: [
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 595 - 2 * 40,
              y2: 5,
              lineWidth: 1,
              lineColor: "#dbdbdb",
            },
          ],
        },
      ],
      margin: [0, 10, 0, 20],
    }
  }

  function paragraph(text: string) {
    return [{
      text,
      margin: [0, 0, 0, 10],
    }]
  }

  function getMonth (date: Date | string): number {
    if (typeof date === "string") {
      date = new Date(date);
    }
    return date.getMonth() + 1;
  }

  function getYear (date: Date | string): number {
    if (typeof date === "string") {
      date = new Date(date);
    }
    return date.getFullYear();
  }

  function getMonthKey(month: number): string {
    return [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ][month - 1];
  };
  pdfMake
    .createPdf({
      defaultStyle: {
        font: "lato",
      },
      pageBreakBefore: (currentNode) =>
        currentNode.headlineLevel === 1 && currentNode.pageNumbers.length > 1,
      content: [
        {
          columns: [
            { width: "*", text: "" },
            {
              text: cv.organization.name,
              fontSize: 18,
              font: "paralucent",
              width: 100,
            },
            { image: "tree", width: 100 },
          ],
          alignment: "right",
          margin: [0, 0, 0, 20],
        },
        {
          text: `${cv.associate.name} - ${cv.title}`,
          style: "h1",
        },
        {
          stack: [
            {
              text: [
                { text: `${$t("cv.person.mobile")}: `, bold: true },
                cv.associate.phone,
              ],
            },
            {
              text: [
                { text: `${$t("cv.person.email")}: `, bold: true },
                cv.associate.email,
              ],
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          stack: [
            /*{
              text: [
                { text: `${$t("cv.industry_competencies")}: `, bold: true },
                cv.industryCompetencies.join(", "),
              ],
            },*/
            {
              text: [
                {
                  text: `${$t("cv.years_of_experience")}: `,
                  bold: true,
                },
                `${cv.yearsOfExperience}`,
              ],
            },
            {
              text: [
                { text: `${$t("cv.key_words")}: `, bold: true },
                cv.skills.map(skill => skill.name).join(", "),
              ],
            },
            {
              text: [
                { text: `${$t("cv.person.birth_year")}: `, bold: true },
                cv.associate.birthDate.slice(0, 4),
              ],
            },
          ],
        },
        hr(),
        {
          stack: paragraph(cv.text),
        },
        hr(),
        {
          stack: [
            {
              text: [
                { text: `${$t("cv.field_of_expertise")}: `, bold: true },
                cv.fieldsOfExpertise.map(fieldOfExpertise => fieldOfExpertise.name).join(", "),
              ],
            },
          ],
          pageBreak: "after",
        },
        {
          text: $t("cv.assignments"),
          style: "h2",
        },
        {
          stack: cv.assignments.map((assignment) => ({
            headlineLevel: 1,
            stack: [
              {
                stack: [
                  {
                    columns: [
                      {
                        width: "30%",
                        stack: [
                          { text: [assignment.company], bold: true },
                          { text: assignment.location },
                          {
                            text: [
                              `${$t(
                                `month.${getMonthKey(
                                  getMonth(assignment.startDate)
                                )}.short`
                              )} ${getYear(assignment.startDate)}`,
                              " - ",
                              ...(assignment.endDate
                                ? [
                                  `${$t(
                                    `month.${getMonthKey(
                                      getMonth(assignment.endDate)
                                    )}.short`
                                  )} ${getYear(assignment.endDate)}`,
                                ]
                                : []),
                            ],
                          },
                        ],
                      },
                      {
                        width: "70%",
                        stack: [
                          { text: assignment.role, bold: true },
                          {
                            stack: paragraph(assignment.text),
                          },
                          {
                            text: assignment.skills.map(skill => skill.name).join(", "),
                            bold: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              hr(),
            ],
          })),
        },
        {
          stack: [
            {
              text: [
                { text: `${$t("cv.educations")}: `, bold: true },
                cv.educations
                  .map((education) =>
                    $t("cv.education_format", {
                      degree: education.degree,
                      school: education.school,
                      years: `${getYear(education.startDate)}${
                        education.endDate ? ` - ${getYear(education.endDate)}` : ""
                      }`,
                    })
                  )
                  .join(", "),
              ],
            },
            {
              text: [
                { text: `${$t("cv.courses")}: `, bold: true },
                cv.courses
                  .map((course) => `${course.name} ${getYear(course.date)}`)
                  .join(", "),
              ],
            },
            hr(),
            { text: $t("cv.employments"), bold: true },
            {
              stack: cv.employments.map((employment) => ({
                columns: [
                  { width: "30%", text: employment.company },
                  {
                    width: "70%",
                    text: [
                      `${$t(
                        `month.${getMonthKey(getMonth(employment.startDate))}.short`
                      )} ${getYear(employment.startDate)}`,
                      " - ",
                      ...(employment.endDate
                        ? [
                          `${$t(
                            `month.${getMonthKey(
                              getMonth(employment.endDate)
                            )}.short`
                          )} ${getYear(employment.endDate)}`,
                        ]
                        : []),
                    ],
                  },
                ],
              })),
            },
            hr(),
            { text: $t("cv.language_skills"), bold: true },
            {
              stack: cv.languageSkills.map((language) => ({
                columns: [
                  { width: "30%", text: language.language },
                  {
                    width: "70%",
                    text: $t(`cv.language_skill_level.${language.level}`),
                  },
                ],
              })),
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
      footer: (currentPage, pageCount) => ({
        margin: [40, 0],
        bold: true,
        columns: [
          { text: `${currentPage} (${pageCount})` },
          {
            text: `${cv.organization.legalName} | Org no: ${cv.organization.organisationNumber}`,
            alignment: "right",
          },
        ],
      }),
      images: {
        tree: treeImage,
      },
      styles: {
        h1: {
          fontSize: 30,
          margin: [0, 0, 0, 10],
          font: "paralucent",
        },
        h2: {
          fontSize: 20,
          margin: [0, 0, 0, 10],
          font: "paralucent",
        },
      },
    })
    .getDataUrl((dataUrl) => {
      if (iframe.value !== null) {
        iframe.value.src = dataUrl;
      }
    });
}

</script>