<template>
  <layout-component>
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
        <Panel>
          <template #header>Login</template>
          <form @submit.prevent="login">
            <div class="field">
              <label for="email">Email</label>
              <InputText ref="emailInput" :disabled="loading" :class="['w-full', { 'p-invalid': loginError }]" id="email" v-model="form.email" />
            </div>
            <div class="field">
              <label for="password">Password</label>
              <InputText ref="passwordInput" :disabled="loading" type="password" :class="['w-full', { 'p-invalid': loginError }]" id="password" v-model="form.password" />
            </div>
            <Message v-if="loginError" severity="error" v-on:close="loginError = false">
              Invalid email or password
            </Message>
            <div class="flex gap-3">
              <Button type="submit" :disabled="loading || !isValid">
                <template v-if="!loading">
                  Login
                </template>
                <i v-else class="fa-solid fa-spinner fa-spin-pulse"></i>
              </Button>
              <div class="flex-grow-1 text-right flex gap-1 justify-content-end align-items-end">
                <router-link to="/register">
                  Register
                </router-link>
                |
                <router-link to="/forgot-password">
                  Forgot password
                </router-link>
              </div>
            </div>
          </form>
        </Panel>
      </div>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';

import LayoutComponent from './layout.component.vue';

import { useAuthStore } from '../stores/auth.store';
import { queryStringFromRoute } from '../utils';
import { onMounted } from 'vue';
import { ClassComponent } from 'primevue/ts-helpers';

const router = useRouter();
const route = useRoute();

const loading = ref(false);
const loginError = ref(false);

const emailInput = ref<HTMLInputElement | null>(null);
const passwordInput = ref<HTMLInputElement | null>(null);

const form = ref({
  email: '',
  password: ''
});

const authStore = useAuthStore();

const isValid = computed(() => {
  return form.value.email.length > 0 && form.value.password.length > 0;
});

const emailQuery = queryStringFromRoute(route, 'email');

onMounted(() => {
  (emailInput.value as any)?.$el.focus();
  if(emailQuery) {
    form.value.email = emailQuery;
    (passwordInput.value as any)?.$el.focus();
  }
});

async function login() {
  loading.value = true;
  try {
    await authStore.login(form.value.email, form.value.password);
    router.push('/cv');
  } catch (error) {
    loginError.value = true;
  } finally {
    loading.value = false;
  }
}
</script>