import { defineStore } from "pinia";
import { computed } from "vue";
import { useSurrealdbConnectionStore } from "./surrealdb-connection.store";
import { useTokenStore } from "./token.store";
import { useLocalStorage } from "@vueuse/core";
import { User } from "../dto/user";


export const useAuthStore = defineStore("auth", () => {
  const tokenStore = useTokenStore();
  const surrealdbStore = useSurrealdbConnectionStore();

  const _me = useLocalStorage<string>("me", null);
  const me = computed<User | undefined>(() =>
    _me.value ? JSON.parse(_me.value) : undefined
  );
  function setMe(value: User | null): void {
    _me.value = JSON.stringify(value);
  }
  function clearMe(): void {
    setMe(null);
  }

  const isAuth = computed(() => !!me.value);

  async function login(email: string, password: string): Promise<void> {
    const token = await surrealdbStore.db.signin({
      namespace: process.env.SURREALDB_NAMESPACE,
      database: process.env.SURREALDB_DATABASE,
      scope: "user",
      email,
      password,
    });

    tokenStore.setToken(token);
    const me = await surrealdbStore.db.info<User>();
    if (me) {
      setMe(me);
    }
  }

  async function logout(): Promise<void> {
    await surrealdbStore.db.invalidate();
    tokenStore.clearToken();
    clearMe();
  }

  return {
    isAuth,
    login,
    logout,
    me,
  };
});
