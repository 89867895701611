<template>
  <DynamicDialog />
  <ConfirmDialog />
  <ConfirmPopup />
  <router-view></router-view>
</template>
<script lang="ts" setup>
import { RouterView } from 'vue-router';
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
</script>