import { useAuthStore } from "./stores/auth.store";

export const isAuth = (to, from, next) => {
  const authStore = useAuthStore();
  if (authStore.isAuth) {
    next();
  } else {
    next("/login");
  }
};

export const isGuest = (to, from, next) => {
  const authStore = useAuthStore();
  if (!authStore.isAuth) {
    next();
  } else {
    next("/me");
  }
};
export const logout = (to, from, next) => {
  const authStore = useAuthStore();
  authStore.logout();
  next("/login");
};
